import React, { Fragment, useRef } from "react";

const Slider = () => {
  const sliderRef = useRef();
  let clicked = 1;

  const slide = () => {
    let width = getComputedStyle(sliderRef.current).getPropertyValue("width");
    let length = sliderRef.current.children[0].children.length;
    sliderRef.current.style.setProperty("--slides", length);
    let slidesStop;
    let slideBy = parseInt(width) * clicked;
    if (clicked >= length) {
      clicked = 1;
      sliderRef.current.style.setProperty("--translate", 0);
    } else {
      sliderRef.current.style.setProperty("--translate", -Math.abs(slideBy));
      clicked++;
    }
  };

  const PauseSlide = () => {
    clearInterval(slidesStop);
  };

  const restartSlide = () => {
    slideanimation();
  };

  const slideanimation = () => {
    slidesStop = setInterval(slide, 2000);
  };

  slideanimation();

  return (
    <Fragment>
      <div
        ref={sliderRef}
        className="slider"
        onMouseEnter={PauseSlide}
        onMouseLeave={restartSlide}
      >
        <div className="sliderContainer">
          <div className="slide">
            <p>Damn Good Work</p>
            <h5>Slide one</h5>
          </div>
          <div className="slide">
            <img src="https://via.placeholder.com/350x150.png" alt="" />
          </div>
          <div className="slide">
            <div>
              <button>Lets talk Action</button>
            </div>
          </div>
          <div className="slide">
            <p>Damn Good Work</p>
            <h5>client one</h5>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Slider;
