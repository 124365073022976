import React from "react";

const Footer = () => (
  <footer>
    <div className="container">
      <p>&copy; copyright 2022</p>
    </div>
  </footer>
);

export default Footer;