import React, { Fragment, useState, useEffect } from "react";

export const Modal = ({ options, children, callBack }) => {
  const [modaloption, setModalOption] = useState(options);
  const closeModal = () => {
    if (modaloption.overlayClickClose) {
      document.body.classList.remove("noScroll");
      callBack({ closeModal: true });
    }
    return false;
  };

  useEffect(() => {
    const modalObj = { ...modaloption, ...options };
    if (document.querySelector(".closeModal")) {
      document.querySelector(".closeModal").addEventListener("click", () => {
        document.body.classList.remove("noScroll");
      });
    }
    setModalOption(modalObj);
    modalObj.show ? document.body.classList.add("noScroll") : null;
    return () => {
      const modalObj = { ...modaloption, ...options };
      setModalOption(modalObj);
    };
  }, [options]);

  const cl = () => {
    let classes = "modal";
    if (modaloption.show) classes += " show";
    else classes += " hide";
    return classes;
  };

  return (
    <Fragment>
      <div className={`${cl()}`}>{children}</div>
      <div className="overlay" onClick={closeModal}></div>
    </Fragment>
  );
};

export const DefaultModalOptions = {
  show: false,
  overlayClickClose: true,
};
