import Slider from "./slider/Slider";

const About = () => {
  return (
    <section className="about">
      <div className="container">
        <div className="leftSection">
          <h2>Let me Introduce Myself</h2>
          <p>
            Hello My Name is Huseini Indorewala. I am Fine Arts Graudate
            Specialsiing in Applied Arts (Graphic Design). Actively looking for
            UX/UI Jobs
          </p>
          <p>
            Skills: lead a Team of Designers and Developers, Client Handling,
            Information Gathering, Responsible for Deliverables, Documentations,
            Interviewing and Grooming Designers and Developers
          </p>
          <p>My Work Experience is 11 years, In past I have worked as:</p>
          <ul>
            <li>Graphic Designer</li>
            <li>Web Designer</li>
            <li>Front End Developer</li>
          </ul>
        </div>
        <div className="rightSection">
          <Slider />
        </div>
      </div>
    </section>
  );
};

export default About;
