import React, { Fragment } from "react";

const PortfolioPiece = ({imgSrc, title, description, click}) => {
  return (
    <div className="portfolioPiece" onClick={click}>
      <div className="thumbnail">
        <img src={imgSrc} title="" />
      </div>
      <div className="description">
        <h5>{title}</h5>
      </div>
    </div>
  );
};

export default PortfolioPiece;
