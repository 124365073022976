const ComingSoon = () => {
    return (
        <section>
            <div className="container">
                <p>Coming Soon</p>
            </div>
        </section>
    )
}

export default ComingSoon;