import React, { Fragment } from "react";

export const PortfolioModalHtml = (prop) => {
  const { header, imagesrc, title, desc, link, footer } = prop.data;
  return (
    <Fragment>
      <div className="modalHeader">{header}</div>
      <div className="modalBody">
        <div className="thumbnail">
          <img src={imagesrc} title="{title}" />
        </div>
        <div className="description">
          <h5>{title}</h5>
          <p>{desc}</p>
          <a href={link}>Read More</a>
        </div>
      </div>
      <div className="modalFooter">{footer}</div>
    </Fragment>
  );
};

export const TestimonialModalHtml = (prop) => {
  const { testi, client, source } = prop.data;
  const callBack = prop.callBack;
  return (
    <Fragment>
      <div className="modalHeader">
        Testimonial
        <span
          className="closeModal"
          onClick={() => {
            callBack({ closeModal: true });
          }}
        >
          X
        </span>
      </div>
      <div className="modalBody">
        <div className="testimonial">
          <blockquote>{testi}</blockquote>
          <h5 className="clientName">{client}</h5>
          <h6 className="source">{source}</h6>
        </div>
      </div>
      <div className="modalFooter">I am the Best!</div>
    </Fragment>
  );
};
