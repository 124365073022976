import React from "react";

const FourNotFour = () => {
  return (
    <section>
      <div className="container">
        <p>Interesting! I have not yet Developed this, Please take a look at : <a href="/">Home</a></p>
      </div>
    </section>
  )
}

export default FourNotFour;