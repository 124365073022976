import React from "react";
import { Link, BrowserRouter as Router } from 'react-router-dom';
import Logo from '../../assets/images/creativedost_logo.png';

const Header = () => (
  <header>
    <div className="container">
      <nav>
          <div className="logo"><img src={Logo}/></div>
          <ul>
            <li> <Link to="/">Home</Link></li>
            <li> <Link to="/portfolio">Portfolio</Link></li>
            <li> <Link to="/hireme">Hire Me!</Link></li>
            <li> <Link to="/contactme">Contact Me</Link></li>
          </ul>
      </nav>
      <div className="secondaryNav">
        <ul>
          <li><a href="#">Linkedin</a></li>
          <li><a href="#">Upwork</a></li>
          <li><a href="#">Freelancer</a></li>
        </ul>
      </div>
    </div>
  </header>
);

export default Header;