const portfolioItems = [
  {
    header: "Svapas",
    title: "Svapas",
    desc: "User Experienc eProject",
    imagesrc: "https://via.placeholder.com/350x150.png",
    footer: "Designed By Huseini Indorewala",
    link: "http://www.google.com",
  },
  {
    header: "CreativeDost",
    title: "CreativeDost",
    desc: "Brand Identity of My Brand",
    imagesrc: "https://via.placeholder.com/350x150.png",
    footer: "Designed By Huseini Indorewala",
    link: "http://www.google.com",
  },
  {
    header: "Svapas",
    title: "Svapas",
    desc: "User Experienc eProject",
    imagesrc: "https://via.placeholder.com/350x150.png",
    footer: "Designed By Huseini Indorewala",
    link: "http://www.google.com",
  },
  {
    header: "CreativeDost",
    title: "CreativeDost",
    desc: "Brand Identity of My Brand",
    imagesrc: "https://via.placeholder.com/350x150.png",
    footer: "Designed By Huseini Indorewala",
    link: "http://www.google.com",
  },
  {
    header: "Svapas",
    title: "Svapas",
    desc: "User Experienc eProject",
    imagesrc: "https://via.placeholder.com/350x150.png",
    footer: "Designed By Huseini Indorewala",
    link: "http://www.google.com",
  },
  {
    header: "CreativeDost",
    title: "CreativeDost",
    desc: "Brand Identity of My Brand",
    imagesrc: "https://via.placeholder.com/350x150.png",
    footer: "Designed By Huseini Indorewala",
    link: "http://www.google.com",
  },
  {
    header: "Svapas",
    title: "Svapas",
    desc: "User Experienc eProject",
    imagesrc: "https://via.placeholder.com/350x150.png",
    footer: "Designed By Huseini Indorewala",
    link: "http://www.google.com",
  },
  {
    header: "CreativeDost",
    title: "CreativeDost",
    desc: "Brand Identity of My Brand",
    imagesrc: "https://via.placeholder.com/350x150.png",
    footer: "Designed By Huseini Indorewala",
    link: "http://www.google.com",
  },
  {
    header: "Svapas",
    title: "Svapas",
    desc: "User Experienc eProject",
    imagesrc: "https://via.placeholder.com/350x150.png",
    footer: "Designed By Huseini Indorewala",
    link: "http://www.google.com",
  },
  {
    header: "CreativeDost",
    title: "CreativeDost",
    desc: "Brand Identity of My Brand",
    imagesrc: "https://via.placeholder.com/350x150.png",
    footer: "Designed By Huseini Indorewala",
    link: "http://www.google.com",
  },
];

export default portfolioItems;
