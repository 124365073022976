import React, {Fragment} from 'react';
import {render} from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Portfolio from './components/Portfolio';
import FourNotFour from './components/FourNotFour';
import ComingSoon from './components/ComingSoon';

const App = () => {
    return (
      <Fragment>
        <Router>
          <Header/>
              <Routes>
                  <Route path="/" element={<Home/>}/>
                  <Route path="portfolio" element={<Portfolio/>}/>
                  <Route path="testimonials" element={<ComingSoon/>}/>
                  <Route path="learn" element={<ComingSoon/>}/>
                  <Route path="hireme" element={<ComingSoon/>}/>
                  <Route path="*" element={<FourNotFour/>}/>
              </Routes>
        </Router>
        <Footer/>
      </Fragment>
    )
};

const root = document.getElementById('origin');

render(<App/>, root);
