import React, { Fragment } from "react";

const Testimonial = ({ Singletestimonial, click }) => {
  const { testi, client, source } = Singletestimonial;
  return (
    <Fragment>
      <div className="testimonial" onClick={click}>
        <blockquote>{testi}</blockquote>
        <h5 className="clientName">{client}</h5>
        <h6 className="source">{source}</h6>
      </div>
    </Fragment>
  );
};

export default Testimonial;
