import React, { Fragment, useState } from "react";
import PortfolioPiece from "./PortfolioPiece";
import { Modal, DefaultModalOptions } from "./Modal";
import { PortfolioModalHtml } from "./ModalHtml";
import portfolioItems from "../../data/Portfolio";

const Portfolio = () => {
  const [options, setOptions] = useState(DefaultModalOptions);
  const openModal = (item) => {
    show = { show: true };
    const modalObj = { ...options, ...show };
    setItem(item);
    setOptions(modalObj);
  };
  const callBack = ({ closeModal }) => {
    if (closeModal) {
      show = { show: false };
      const modalObj = { ...DefaultModalOptions, ...show };
      setOptions(modalObj);
    }
  };
  const [item, setItem] = useState({});

  return (
    <Fragment>
      <section className="portfolio">
        <div className="container">
          {portfolioItems.map((item, index) => {
            return (
              <PortfolioPiece
                key={index}
                click={() => {
                  openModal(item);
                }}
                imgSrc={item.imagesrc}
                title={item.title}
                description={item.desc}
              />
            );
          })}
        </div>
        <Modal options={options} callBack={callBack}>
          <PortfolioModalHtml data={item} />
        </Modal>
      </section>
    </Fragment>
  );
};

export default Portfolio;
