import React, { Fragment } from "react";
import About from "./About";
import HomeIllustration from "../../assets/images/heroImg.svg";
import TestimonialSection from "./TestimonialSection";

const Home = () => {
  return (
    <Fragment>
      <section className="home">
        <div className="container">
          <div className="leftSection">
            <div className="supHeadline"> Are you</div>
            <h1>Looking For</h1>
            <ul>
              <li>User Experience Designer</li>
              <li>User Interface Designer</li>
              <li>Creative Designer with Development Skills</li>
            </ul>
            <div className="actionItems">
              <a href="#" className="primary">
                Hire Me!
              </a>
              <a href="#" className="secondary">
                Let's Talk
              </a>
            </div>
          </div>
          <div className="rightSection">
            <img src={HomeIllustration} />
          </div>
        </div>
      </section>
      <About />
      <TestimonialSection />
    </Fragment>
  );
};

export default Home;
