import React, { Fragment, useState } from "react";
import Testimonial from "./Testimonial";
import TestimonialsData from "../../data/TestimonialsData";
import { Modal, DefaultModalOptions } from "./Modal";
import { TestimonialModalHtml } from "./ModalHtml";

const TestimonialSection = () => {
  let overlayClickClose = { overlayClickClose: false };
  let changedDefaultOptions = { ...DefaultModalOptions, ...overlayClickClose };
  const [options, setOptions] = useState(changedDefaultOptions);
  const [item, setItem] = useState({});
  const openModal = (item) => {
    let show = { show: true };
    const modalObj = { ...options, ...show };
    setItem(item);
    setOptions(modalObj);
  };
  const callBack = ({ closeModal }) => {
    if (closeModal) {
      show = { show: false };
      const modalObj = { ...changedDefaultOptions, ...show };
      setOptions(modalObj);
    }
  };
  return (
    <Fragment>
      <section className="testimonialSection masanoryLayout">
        <div className="container">
          <h3 className="sectionHeading">Testimonials</h3>
          <div className="testimonialsWrapper">
            {TestimonialsData.map((item, index) => {
              return (
                <Testimonial
                  key={index}
                  Singletestimonial={item}
                  click={() => {
                    openModal(item);
                  }}
                />
              );
            })}
          </div>
        </div>
      </section>
      <Modal options={options} callBack={callBack}>
        <TestimonialModalHtml data={item} callBack={callBack} />
      </Modal>
    </Fragment>
  );
};

export default TestimonialSection;
