const TestimonialsData = [
  {
    testi:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores rem vel obcaecati iste quis!Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores rem vel obcaecati iste quis!Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores rem vel obcaecati iste quis!Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores rem vel obcaecati iste quis!Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores rem vel obcaecati iste quis!",
    client: "Client Name",
    source: "Website Name",
  },
  {
    testi:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse vel nostrum eum obcaecati cupiditate. Deserunt minima hic consequatur, non quaerat maiores. Omnis, cumque possimus.",
    client: "Client Name",
    source: "Website Name",
  },
  {
    testi:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores rem vel obcaecati iste quis!",
    client: "Client Name",
    source: "Website Name",
  },
  {
    testi:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores rem vel obcaecati iste quis! Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores rem vel obcaecati iste quis! ",
    client: "Client Name",
    source: "Website Name",
  },
  {
    testi:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse vel nostrum eum obcaecati cupiditate. Deserunt minima hic consequatur, non quaerat maiores. Omnis, cumque possimus.",
    client: "Client Name",
    source: "Website Name",
  },
  {
    testi:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores rem vel obcaecati iste quis!",
    client: "Client Name",
    source: "Website Name",
  },
];

export default TestimonialsData;
